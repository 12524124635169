import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import VueGtag from "vue-gtag";
import NProgress from "vue-nprogress";
import Urls from "@/utils/url.js";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import i18n from "@/utils/lang.js";
import english from "@/utils/en.js";
import indonesia from "@/utils/id.js";

Vue.use(NProgress);
Vue.config.productionTip = true;
Vue.use(CKEditor);
Vue.use(Antd);

Vue.mixin(Urls);

const nprogress = new NProgress();

Vue.use(VueGtag, {
  config: { id: "G-R6TE3GBSZZ" },
});

i18n.locale = "id";
i18n.messages = {
  id: indonesia,
  en: english,
};

new Vue({
  nprogress,
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
