const IndexAdminCategories = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/Administrator/categories/index.vue"
  );

const ListCategories = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/Administrator/categories/list.vue"
  );

const CreateCategories = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/Administrator/categories/create.vue"
  );

const EditCategories = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/Administrator/categories/edit.vue"
  );

export default [
  {
    path: "artikel/categories",
    name: "IndexAdminCategories",
    component: IndexAdminCategories,
    meta: { requiresAuth: true, title: "Categories" },
    children: [
      {
        path: "",
        name: "ListCategories",
        component: ListCategories,
        meta: {
          requiresAuth: true,
          title: "List Categories",
          key: "Categories",
        },
      },
      {
        path: "create",
        name: "CreateCategories",
        component: CreateCategories,
        meta: {
          requiresAuth: true,
          title: "Create Categories",
          key: "Create Categories",
        },
      },
      {
        path: "edit/:id",
        name: "EditCategories",
        component: EditCategories,
        meta: {
          requiresAuth: true,
          title: "Edit Categories",
          key: "Edit Categories",
        },
      },
    ],
  },
];
