import Vue from "vue";
import Vuex from "vuex";
import Auth from "./auth.js";
import Categories from "./categories.js";
import FileManager from "./filemanager.js";
import Post from "./post.js";
import Public from "./public.js";
import Career from "./career.js";
import Applicant from "./applicant.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoading: false,
    uploadPercentage: 0,
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    Auth,
    Categories,
    FileManager,
    Post,
    Public,
    Career,
    Applicant,
  },
});
