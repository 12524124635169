import $axios from "@/utils/axios.js";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  GetEditPost(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get("/administrator/post/edit/" + params.id)
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
  GetListPost(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get("/administrator/post", {
          params: {
            ...params,
          },
        })
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
  HapusPost(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .delete("/administrator/post/delete/" + params.id)
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
  EditPost(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .post("/administrator/post/edit", {
          ...params,
        })
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
  InsertPost(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .post("/administrator/post/create", {
          ...params,
        })
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
};

export default {
  namespace: true,
  state,
  getters,
  mutations,
  actions,
};
