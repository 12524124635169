const IndexAdminPost = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/Administrator/post/index.vue"
  );

const ListPost = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/Administrator/post/list.vue"
  );

const CreatePost = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/Administrator/post/create.vue"
  );

const EditPost = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/Administrator/post/edit.vue"
  );

export default [
  {
    path: "artikel/post",
    name: "IndexAdminPost",
    component: IndexAdminPost,
    meta: { requiresAuth: true, title: "Post" },
    children: [
      {
        path: "",
        name: "ListPost",
        component: ListPost,
        meta: {
          requiresAuth: true,
          title: "List Post",
          key: "Post",
        },
      },
      {
        path: "create",
        name: "CreatePost",
        component: CreatePost,
        meta: {
          requiresAuth: true,
          title: "Create Post",
          key: "Create Post",
        },
      },
      {
        path: "edit/:id",
        name: "EditPost",
        component: EditPost,
        meta: {
          requiresAuth: true,
          title: "Edit Post",
          key: "Edit Post",
        },
      },
    ],
  },
];
