const IndexHome = () =>
  /* webpackChunkName: "view-[request]" */ import("@/views/index.vue");

const ContentHome = () =>
  /* webpackChunkName: "view-[request]" */ import("@/views/Pages/HomeView.vue");

const AboutUs = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/Pages/AboutView.vue"
  );

const WhyAndika = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/Pages/WhyAndikaView.vue"
  );

const OurManagement = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/Pages/OurManagementView.vue"
  );

const ContactUs = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/Pages/ContactView.vue"
  );

const product = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/Pages/ProductView.vue"
  );

const Artikel = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/Pages/ArticleView.vue"
  );

const ArtikelDetail = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/Pages/ArticleDetailView.vue"
  );

const Privacy = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/Pages/PrivacyPolcyView.vue"
  );

const termcond = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/Pages/TermConditionView.vue"
  );

const career = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/Pages/CareerView.vue"
  );

const careerlist = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/Pages/CareerListView.vue"
  );

const careerlistdetail = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/Pages/careerListDetail.vue"
  );

const formcareer = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/Pages/formcareer.vue"
  );

export default [
  {
    path: "",
    name: "home",
    component: IndexHome,
    meta: {
      title: "Beranda",
    },
    children: [
      {
        path: "/",
        name: "beranda",
        component: ContentHome,
        meta: {
          title: "Beranda",
          metaTags: [
            {
              name: "description",
              content:
                "Andika Assistant merupakan salah satu broker asuransi terbaik di Indonesia. Sebagai broker asuransi, Kami siap membantu Anda dalam memilih asuransi sesuai dengan kebutuhan Anda.",
            },
            {
              name: "keywords",
              content:
                "broker,asuransi,jakarta,indonesia, broker asuransi, broker asuransi kesehatan, andika assistant, andika",
            },
            {
              property: "og:title",
              content:
                "Andika Assistant | Employee Benefit &amp;amp; Insurance Consultant",
            },

            { property: "og:type", content: "website" },
            {
              property: "og:url",
              content: "https://www.andikaassistant.co.id/",
            },
            {
              property: "og:site_name",
              content:
                "Andika Assistant | Employee Benefit &amp; Insurance Consultant",
            },
            {
              property: "og:description",
              content:
                "Andika Assistant merupakan salah satu broker asuransi dan konsultan asuransi terbaik di Indonesia. Sebagai broker asuransi dan konsultan asuransi, Kami siap membantu Anda dalam memilih asuransi sesuai dengan kebutuhan Anda.",
            },
          ],
        },
      },

      {
        path: "/product",
        name: "product",
        component: product,
        meta: {
          title: "Our Products - Employee Benefit & Insurance Consultant",
          metaTags: [
            {
              name: "description",
              content:
                "Andika Assistant merupakan salah satu broker asuransi terbaik di Indonesia. Sebagai broker asuransi, Kami siap membantu Anda dalam memilih asuransi sesuai dengan kebutuhan Anda.",
            },
            {
              name: "keywords",
              content: "broker,asuransi,jakarta,indonesia",
            },
            {
              property: "og:title",
              content:
                "Andika Assistant - Employee Benefit &amp;amp; Insurance Consultant",
            },

            { property: "og:type", content: "website" },
            {
              property: "og:url",
              content: "https://www.andikaassistant.co.id/",
            },
            {
              property: "og:site_name",
              content:
                "Andika Assistant - Employee Benefit &amp; Insurance Consultant",
            },
            {
              property: "og:description",
              content:
                "Andika Assistant merupakan salah satu broker asuransi dan konsultan asuransi terbaik di Indonesia. Sebagai broker asuransi dan konsultan asuransi, Kami siap membantu Anda dalam memilih asuransi sesuai dengan kebutuhan Anda.",
            },
          ],
        },
      },

      {
        path: "/about-us",
        name: "AboutUs",
        component: AboutUs,
        meta: {
          title: "About Us - Employee Benefit & Insurance Consultant",
          metaTags: [
            {
              name: "description",
              content:
                "Andika Assistant merupakan salah satu broker asuransi terbaik di Indonesia. Sebagai broker asuransi, Kami siap membantu Anda dalam memilih asuransi sesuai dengan kebutuhan Anda.",
            },
            {
              name: "keywords",
              content: "broker,asuransi,jakarta,indonesia",
            },
            {
              property: "og:title",
              content:
                "Andika Assistant - Employee Benefit &amp;amp; Insurance Consultant",
            },

            { property: "og:type", content: "website" },
            {
              property: "og:url",
              content: "https://www.andikaassistant.co.id/",
            },
            {
              property: "og:site_name",
              content:
                "Andika Assistant - Employee Benefit &amp; Insurance Consultant",
            },
            {
              property: "og:description",
              content:
                "Andika Assistant merupakan salah satu broker asuransi dan konsultan asuransi terbaik di Indonesia. Sebagai broker asuransi dan konsultan asuransi, Kami siap membantu Anda dalam memilih asuransi sesuai dengan kebutuhan Anda.",
            },
          ],
        },
      },

      {
        path: "/about-us/why-andika",
        name: "WhyAndika",
        component: WhyAndika,
        meta: {
          title: "Why Andika - Employee Benefit & Insurance Consultant",
          metaTags: [
            {
              name: "description",
              content:
                "Andika Assistant merupakan salah satu broker asuransi terbaik di Indonesia. Sebagai broker asuransi, Kami siap membantu Anda dalam memilih asuransi sesuai dengan kebutuhan Anda.",
            },
            {
              name: "keywords",
              content: "broker,asuransi,jakarta,indonesia",
            },
            {
              property: "og:title",
              content:
                "Andika Assistant - Employee Benefit &amp;amp; Insurance Consultant",
            },

            { property: "og:type", content: "website" },
            {
              property: "og:url",
              content: "https://www.andikaassistant.co.id/",
            },
            {
              property: "og:site_name",
              content:
                "Andika Assistant - Employee Benefit &amp; Insurance Consultant",
            },
            {
              property: "og:description",
              content:
                "Andika Assistant merupakan salah satu broker asuransi dan konsultan asuransi terbaik di Indonesia. Sebagai broker asuransi dan konsultan asuransi, Kami siap membantu Anda dalam memilih asuransi sesuai dengan kebutuhan Anda.",
            },
          ],
        },
      },

      {
        path: "/about-us/our-management",
        name: "OurManagement",
        component: OurManagement,
        meta: {
          title: "Our Management - Employee Benefit & Insurance Consultant",
          metaTags: [
            {
              name: "description",
              content:
                "Andika Assistant merupakan salah satu broker asuransi terbaik di Indonesia. Sebagai broker asuransi, Kami siap membantu Anda dalam memilih asuransi sesuai dengan kebutuhan Anda.",
            },
            {
              name: "keywords",
              content: "broker,asuransi,jakarta,indonesia",
            },
            {
              property: "og:title",
              content:
                "Andika Assistant - Employee Benefit &amp;amp; Insurance Consultant",
            },

            { property: "og:type", content: "website" },
            {
              property: "og:url",
              content: "https://www.andikaassistant.co.id/",
            },
            {
              property: "og:site_name",
              content:
                "Andika Assistant - Employee Benefit &amp; Insurance Consultant",
            },
            {
              property: "og:description",
              content:
                "Andika Assistant merupakan salah satu broker asuransi dan konsultan asuransi terbaik di Indonesia. Sebagai broker asuransi dan konsultan asuransi, Kami siap membantu Anda dalam memilih asuransi sesuai dengan kebutuhan Anda.",
            },
          ],
        },
      },

      {
        path: "/contact-us",
        name: "ContactUs",
        component: ContactUs,
        meta: {
          title: "Contact Us - Employee Benefit & Insurance Consultant",
          metaTags: [
            {
              name: "description",
              content:
                "Andika Assistant merupakan salah satu broker asuransi terbaik di Indonesia. Sebagai broker asuransi, Kami siap membantu Anda dalam memilih asuransi sesuai dengan kebutuhan Anda.",
            },
            {
              name: "keywords",
              content: "broker,asuransi,jakarta,indonesia",
            },
            {
              property: "og:title",
              content:
                "Andika Assistant - Employee Benefit &amp;amp; Insurance Consultant",
            },

            { property: "og:type", content: "website" },
            {
              property: "og:url",
              content: "https://www.andikaassistant.co.id/",
            },
            {
              property: "og:site_name",
              content:
                "Andika Assistant - Employee Benefit &amp; Insurance Consultant",
            },
            {
              property: "og:description",
              content:
                "Andika Assistant merupakan salah satu broker asuransi dan konsultan asuransi terbaik di Indonesia. Sebagai broker asuransi dan konsultan asuransi, Kami siap membantu Anda dalam memilih asuransi sesuai dengan kebutuhan Anda.",
            },
          ],
        },
      },

      {
        path: "/artikel",
        name: "Artikel",
        component: Artikel,
        meta: {
          title: "Artikel - Employee Benefit & Insurance Consultant",
          metaTags: [
            {
              name: "description",
              content:
                "Andika Assistant merupakan salah satu broker asuransi terbaik di Indonesia. Sebagai broker asuransi, Kami siap membantu Anda dalam memilih asuransi sesuai dengan kebutuhan Anda.",
            },
            {
              name: "keywords",
              content: "broker,asuransi,jakarta,indonesia",
            },
            {
              property: "og:title",
              content:
                "Andika Assistant - Employee Benefit &amp;amp; Insurance Consultant",
            },

            { property: "og:type", content: "website" },
            {
              property: "og:url",
              content: "https://www.andikaassistant.co.id/",
            },
            {
              property: "og:site_name",
              content:
                "Andika Assistant - Employee Benefit &amp; Insurance Consultant",
            },
            {
              property: "og:description",
              content:
                "Andika Assistant merupakan salah satu broker asuransi dan konsultan asuransi terbaik di Indonesia. Sebagai broker asuransi dan konsultan asuransi, Kami siap membantu Anda dalam memilih asuransi sesuai dengan kebutuhan Anda.",
            },
          ],
        },
      },

      {
        path: "/artikel/:id/:slug",
        name: "ArtikelDetail",
        component: ArtikelDetail,
        meta: {
          title: "Detail Artikel - Employee Benefit & Insurance Consultant",
        },
      },

      {
        path: "/privacy-policy",
        name: "privacy",
        component: Privacy,
        meta: {
          title: "Privacy Polcy - Employee Benefit & Insurance Consultant",
          metaTags: [
            {
              name: "description",
              content:
                "Andika Assistant merupakan salah satu broker asuransi terbaik di Indonesia. Sebagai broker asuransi, Kami siap membantu Anda dalam memilih asuransi sesuai dengan kebutuhan Anda.",
            },
            {
              name: "keywords",
              content: "broker,asuransi,jakarta,indonesia",
            },
            {
              property: "og:title",
              content:
                "Andika Assistant - Employee Benefit &amp;amp; Insurance Consultant",
            },

            { property: "og:type", content: "website" },
            {
              property: "og:url",
              content: "https://www.andikaassistant.co.id/",
            },
            {
              property: "og:site_name",
              content:
                "Andika Assistant - Employee Benefit &amp; Insurance Consultant",
            },
            {
              property: "og:description",
              content:
                "Andika Assistant merupakan salah satu broker asuransi dan konsultan asuransi terbaik di Indonesia. Sebagai broker asuransi dan konsultan asuransi, Kami siap membantu Anda dalam memilih asuransi sesuai dengan kebutuhan Anda.",
            },
          ],
        },
      },
      {
        path: "/term-conditions",
        name: "termcond",
        component: termcond,
        meta: {
          title: "Terms & Conditions - Employee Benefit & Insurance Consultant",
          metaTags: [
            {
              name: "description",
              content:
                "Andika Assistant merupakan salah satu broker asuransi terbaik di Indonesia. Sebagai broker asuransi, Kami siap membantu Anda dalam memilih asuransi sesuai dengan kebutuhan Anda.",
            },
            {
              name: "keywords",
              content: "broker,asuransi,jakarta,indonesia",
            },
            {
              property: "og:title",
              content:
                "Andika Assistant - Employee Benefit &amp;amp; Insurance Consultant",
            },

            { property: "og:type", content: "website" },
            {
              property: "og:url",
              content: "https://www.andikaassistant.co.id/",
            },
            {
              property: "og:site_name",
              content:
                "Andika Assistant - Employee Benefit &amp; Insurance Consultant",
            },
            {
              property: "og:description",
              content:
                "Andika Assistant merupakan salah satu broker asuransi dan konsultan asuransi terbaik di Indonesia. Sebagai broker asuransi dan konsultan asuransi, Kami siap membantu Anda dalam memilih asuransi sesuai dengan kebutuhan Anda.",
            },
          ],
        },
      },

      {
        path: "/karir",
        name: "career",
        component: career,
        meta: {
          title: "Career - Employee Benefit & Insurance Consultant",
          metaTags: [
            {
              name: "description",
              content:
                "Andika Assistant merupakan salah satu broker asuransi terbaik di Indonesia. Sebagai broker asuransi, Kami siap membantu Anda dalam memilih asuransi sesuai dengan kebutuhan Anda.",
            },
            {
              name: "keywords",
              content: "broker,asuransi,jakarta,indonesia",
            },
            {
              property: "og:title",
              content:
                "Andika Assistant - Employee Benefit &amp;amp; Insurance Consultant",
            },

            { property: "og:type", content: "website" },
            {
              property: "og:url",
              content: "https://www.andikaassistant.co.id/",
            },
            {
              property: "og:site_name",
              content:
                "Andika Assistant - Employee Benefit &amp; Insurance Consultant",
            },
            {
              property: "og:description",
              content:
                "Andika Assistant merupakan salah satu broker asuransi dan konsultan asuransi terbaik di Indonesia. Sebagai broker asuransi dan konsultan asuransi, Kami siap membantu Anda dalam memilih asuransi sesuai dengan kebutuhan Anda.",
            },
          ],
        },
      },
      {
        path: "/karir/lowongan",
        name: "careerlist",
        component: careerlist,
        meta: {
          title: "Career - Employee Benefit & Insurance Consultant",
          metaTags: [
            {
              name: "description",
              content:
                "Andika Assistant merupakan salah satu broker asuransi terbaik di Indonesia. Sebagai broker asuransi, Kami siap membantu Anda dalam memilih asuransi sesuai dengan kebutuhan Anda.",
            },
            {
              name: "keywords",
              content: "broker,asuransi,jakarta,indonesia",
            },
            {
              property: "og:title",
              content:
                "Andika Assistant - Employee Benefit &amp;amp; Insurance Consultant",
            },

            { property: "og:type", content: "website" },
            {
              property: "og:url",
              content: "https://www.andikaassistant.co.id/",
            },
            {
              property: "og:site_name",
              content:
                "Andika Assistant - Employee Benefit &amp; Insurance Consultant",
            },
            {
              property: "og:description",
              content:
                "Andika Assistant merupakan salah satu broker asuransi dan konsultan asuransi terbaik di Indonesia. Sebagai broker asuransi dan konsultan asuransi, Kami siap membantu Anda dalam memilih asuransi sesuai dengan kebutuhan Anda.",
            },
          ],
        },
      },
      {
        path: "/karir/lowongan/detail/:id",
        name: "careerlistdetail",
        component: careerlistdetail,
        meta: {
          title: "Career - Employee Benefit & Insurance Consultant",
          metaTags: [
            {
              name: "description",
              content:
                "Andika Assistant merupakan salah satu broker asuransi terbaik di Indonesia. Sebagai broker asuransi, Kami siap membantu Anda dalam memilih asuransi sesuai dengan kebutuhan Anda.",
            },
            {
              name: "keywords",
              content: "broker,asuransi,jakarta,indonesia",
            },
            {
              property: "og:title",
              content:
                "Andika Assistant - Employee Benefit &amp;amp; Insurance Consultant",
            },

            { property: "og:type", content: "website" },
            {
              property: "og:url",
              content: "https://www.andikaassistant.co.id/",
            },
            {
              property: "og:site_name",
              content:
                "Andika Assistant - Employee Benefit &amp; Insurance Consultant",
            },
            {
              property: "og:description",
              content:
                "Andika Assistant merupakan salah satu broker asuransi dan konsultan asuransi terbaik di Indonesia. Sebagai broker asuransi dan konsultan asuransi, Kami siap membantu Anda dalam memilih asuransi sesuai dengan kebutuhan Anda.",
            },
          ],
        },
      },
      {
        path: "/karir/lowongan/apply/:id",
        name: "formcareer",
        component: formcareer,
        meta: {
          title: "Career - Employee Benefit & Insurance Consultant",
          metaTags: [
            {
              name: "description",
              content:
                "Andika Assistant merupakan salah satu broker asuransi terbaik di Indonesia. Sebagai broker asuransi, Kami siap membantu Anda dalam memilih asuransi sesuai dengan kebutuhan Anda.",
            },
            {
              name: "keywords",
              content: "broker,asuransi,jakarta,indonesia",
            },
            {
              property: "og:title",
              content:
                "Andika Assistant - Employee Benefit &amp;amp; Insurance Consultant",
            },

            { property: "og:type", content: "website" },
            {
              property: "og:url",
              content: "https://www.andikaassistant.co.id/",
            },
            {
              property: "og:site_name",
              content:
                "Andika Assistant - Employee Benefit &amp; Insurance Consultant",
            },
            {
              property: "og:description",
              content:
                "Andika Assistant merupakan salah satu broker asuransi dan konsultan asuransi terbaik di Indonesia. Sebagai broker asuransi dan konsultan asuransi, Kami siap membantu Anda dalam memilih asuransi sesuai dengan kebutuhan Anda.",
            },
          ],
        },
      },
    ],
  },
  {
    path: "/asa",
    name: "Login",
    component: () =>
      /* webpackChunkName: "view-[request]" */ import("@/views/LoginView.vue"),
  },

  {
    path: "*",
    name: "NotFound",
    component: () =>
      /* webpackChunkName: "view-[request]" */ import(
        "@/components/error/404.vue"
      ),
  },
];
