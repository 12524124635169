const IndexAdminApplicant = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/Administrator/applicant/index.vue"
  );

const ListApplicant = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/Administrator/applicant/list.vue"
  );

const DetailApplicant = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/Administrator/applicant/detail.vue"
  );

export default [
  {
    path: "career/applicant",
    name: "IndexAdminApplicant",
    component: IndexAdminApplicant,
    meta: { requiresAuth: true, title: "Applicant" },
    children: [
      {
        path: "",
        name: "ListApplicant",
        component: ListApplicant,
        meta: {
          requiresAuth: true,
          title: "List Applicant",
          key: "Applicant",
        },
      },

      {
        path: "detail/:id",
        name: "DetailApplicant",
        component: DetailApplicant,
        meta: {
          requiresAuth: true,
          title: "Detail Applicant",
          key: "Detail Applicant",
        },
      },
    ],
  },
];
