import $axios from "@/utils/axios.js";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  GetListApplicant(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get("/administrator/career/applicant", {
          params: { ...params },
        })
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },

  DetailApplicant(context, payload) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get("/administrator/career/applicant/" + payload.id)
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },

  DetailApplicationAction(context, payload) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .put("/administrator/career/approve/" + payload.id, {
          ...payload,
        })
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
};

export default {
  namespace: true,
  state,
  getters,
  mutations,
  actions,
};
