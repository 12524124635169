const english = {
  menu: {
    home: "Home",
    product: "Product",
    company: "About Us",
    contact: "Contact Us",
    article: "Articles",
    aboutus: "Profile",
    whyandika: "Why Andika",
    ourmanagement: "Our Management",
    career: "Career",
  },
  header: {
    slider1: "Finding Insurance That Fits the Needs",
    tagline1: "Get the best protection with our precise reccomendation",
    button: "Contact Us",
    slider2: "Comprehensive Services Beyond Insurance",
    tagline2:
      "We’re committed to give full services more than just insurance solution",
    slider3: "Choosing The Perfect Plan,  Stress-free",
    tagline3: "Selecting insurance coverage with ease,  secure and comfort",
    slider4: "Serve With Heart",
    tagline4:
      "Insurance assistance that bring peace and comfort in every condition",
  },
  home: {
    history: "Andika Assistant History",
    historydetail:
      "Started as Andika Agency,  the company was established on September 1st, 1999 under the name PT. Andika Adhi Sejahtera. Since November 5th,  2012 evolve into Andika Broker under the name PT. Andika Mitra Sejati.",
    solution: "The Solution to Insurance Needs",
    taglinesolution:
      "We give comprehensive services to solve company’s insurance problem",
    milestonesolution1: "Accurate Recommendation",
    submilestonesolution1: "Insurance information from professional staff",
    milestonesolution2: "Comprehensive Services",
    submilestonesolution2: "All in one insurance services that is worth it",
    milestonesolution3: "Claim Assistance",
    submilestonesolution3:
      "From submission to approval,  we got your claim covered",
    milestonesolution4: "Product that Fits",
    submilestonesolution4:
      "Tailored product recommendation according to the needs.",
    personalaccident: "Protection from accident",
    healtinsurance: "Safe and secure everytime",
    lifeinsurance: "Tranquillity for your loved ones",
    provider: "Finding Provider Effortlessly",
    subprovider:
      "With the Andika Assistant app,  finding an insurance provider has never been easier. In just a few touches,  providers can be found along with detailed and complete information.",
    accuration: "Accuration",
    rating: "Rating",
    benefit: "Know Your Benefit",
    subbenefit:
      "Detailed benefit information in the palm of your hand with Andika Assistant app",
    outpatient: "Outpatient",
    suboutpatient:
      "Consultation with general practitioners and specialists including medicines for optimal healing of your disease",
    inpatient: "Inpatient",
    subinpatient: "Room charges,  surgery,  and post-operative care",
    dental: "Dental",
    subdental:
      "Regular dental visits,  prevention of dental diseases,  fillings and extractions",
    glasses: "Glasses",
    subglasses:
      "Eye examinations,  replacement of glasses or contact lenses,  up to glasses frames",
    claimfingertips: "Claim Submission at Your Fingertips",
    subclaimfingertips: "Easy,  fast and transparent with Andika Assistant app",
    claimsubmission: "Claim Submission",
    subclaimsubmission:
      "Complete your claim submission with just a few taps. Hassle-free!",
    claimmonitoring: "Claim Monitoring",
    subclaimmonitoring: "Transparent claim information for your convenience",
    claimhistory: "Claim History",
    subclaimhistory: "Track claim record in one place",
    asuransikesehatan: "Perfect Solution for Health Insurance",
    subasuransikesehatan: "The right app,  for the right health protection",
    ourpartner: "Our Partner",
    latestarticle: "Latest Article",
  },
  product: {
    title: "Product",
    subtitle: "Maximum protection,  Fits Perfectly",
    detailhealth:
      "<p>Health insurance is a crucial investment for businesses. It helps to ensure employees feel secure and protected in all circumstances. An optimum health insurance is also an additional highlight that makes your company stand out. Andika Assistant can assist your company in obtaining comprehensive health coverage, including:</p><ul><li>Hospitalization &amp; Surgery</li><li>Outpatient</li><li>Maternity</li><li>Dental Care</li><li>Glasses</li><li>Additional Benefits in the form of Emergency Evacuation, Death Benefit, and Other Benefits</li></ul><p>at Andika Assistant, we offer a wide range of services related to selecting health insurance. Our team of professionals can provide recommendations and help you choose the right plan. Additionally, we have a 24-hour helpline available for support. We also offer claim analysis services to ensure that your claims are properly documented and stand a higher chance of being approved by the provider.</p>",
    detailaccident:
      "<p>As a company,  it's important to protect your employees from any accidents that might happen. If your company operates in an industry with a high risk of accidents,  personal accident insurance is a smart way to protect your assets and give your employees peace of mind.&nbsp;<br><br>This insurance covers injuries caused by accidents and also provides financial support to the family if something unexpected occurs. Get the best personal accident insurance recommendation from Andika Assistant professional staff,  along with additional insurance that attached to the main benefit,  such as:&nbsp;<br>&nbsp;</p><ol><li>Permanent Disability</li><li>Temporary Disability</li><li>Medical Expenses</li></ol><p><br>At Andika Assistant,  we offer a wide range of services related to selecting health insurance. Our team of professionals can provide recommendations and help you choose the right plan. Additionally,  we have a 24-hour helpline available for support. We also offer claim analysis services to ensure that your claims are properly documented and stand a higher chance of being approved by the provider.</p>",
    detaillife:
      "<p>Life insurance is a way of showing love and care for your family. It helps to ensure your loved ones are financially secure and able to maintain their standard of living, even if you are not around to provide for them.&nbsp;<br><br>Life insurance characteristics:&nbsp;<br>&nbsp;</p><ul><li>Provides coverage for a certain period of time called the policy term.</li><li>Defined benefits are paid if the insured dies within the stipulated period and the policy is still in force.</li><li>Good life insurance policy protection usually has additional benefits(riders)added to the basic policy.&nbsp;<br>&nbsp;</li></ul><p>Riders for life insurance:&nbsp;<br><br><strong>Accidental Death and Disablement Benefit&nbsp;</strong><br>The benefit is 100%of the sum insured in the event of death due to an accident. For accidental disability, the coverage will be adjusted according to the percentage table.&nbsp;</p><p><br><strong>Total and Permanent Disablement Benefit&nbsp;</strong><br>A benefit of 100%of the total sum insurance if the insured is totally disabled due to illness or accident within 90 days of the incident.&nbsp;<br><br>At Andika Assistant, we offer a wide range of services related to selecting health insurance.Our team of professionals can provide recommendations and help you choose the right plan.Additionally, we have a 24-hour helpline available for support. We also offer claim analysis services to ensure that your claims are properly documented and stand a higher chance of being approved by the insurance provider.</p><p>&nbsp;</p><p>&nbsp;</p>",
  },
  profile: {
    title: "Profile",
    subtitle: "An insurance consultant providing top services in Indonesia",
    aboutandika: "About Andika Assistant",
    textaboutandika:
      "Our priority is always you by selecting the best product that you can get,  while also maintaining exceptional services.",
    helpyou: "Helps You Understand",
    texthelpyou:
      "Our goal is to ensure that you fully understand any insurance recommendations we provide. To do this,  we are happy to share our expertise and knowledge with to help company make informed decision.",
    commitment: "Our Commitment",
    textcommitment:
      "Our top priority is always our client needs. We strive to provide trusted recommendations and thorough product knowledge to ensure confident in every decision.",
    textiserve: "Our core values",
    innovative: "Innovate to create added value",
    spiritofexcellent: "Give more than the best",
    emphaty: "Understand the needs and give the best solution",
    reliable: "Trustworthy for all insurance needs",
    versatile: "Adapt to conditions while maintaining high quality services",
    enthusiastic:
      "Providing services in a positive,  energized and proactive manner",
  },
  whyandika: {
    title: "Why Andika?",
    subtitle: "Offering various added value services for insurance needs",
    bestinsurance: "The Best Insurance Starts Here",
    textbestinsurance:
      "Andika Assistant is here to cover all insurance needs. We are committed to assisting companies with any insurance-related issues. Our comprehensive services guarantee the perfect insurance,  while also assisting with the submission and resolution of claims. With us,  all about insurance is an easy process.",
    criteriainsurance: "Our Partner Criteria",
    textcriteriainsurance:
      "To ensure perfect services,  we only partnering with the best insurance company",
    solvability:
      "Our insurance partners are required to have a high level of solvency",
    stability:
      "Our insurance partners guarantee to have stable financial condition",
    reputation: "The best reputation that only gives the best protection",
    precise:
      "Services that in line with our commitment: to give nothing but the best",
    management: "Manage by the best professional in insurance business",
    speed: "Delivered services as fast as possible",
    comprehensive: "Giving Comprehensive Services",
    textcomprehensive: "Assisting insurance needs from A to Z",
    benefit: "Benefit Analysis",
    textbenefit: "Tailor made benefit recommendation according to the needs",
    insuranceselection: "Insurance Selection",
    textinsuranceselection:
      "Helping insurance selection that ensure optimum protection",
    helpline: "24/7 HelpLine",
    texthelpline:
      "Assistance that gives comfort and sense of security all day long",
    negotiation: "Claim Negotiation",
    textnegotiation: "Support that guarantee appropriate right are fulfilled",
    accompaniment: "Accompaniment",
    textaccompaniment: "Continuous guidance on every insurance decision",
    administration: "Data Administration",
    textadministration:
      "Secure,  organized,  and accurate storage of client’s data",
    undestandinsurance: "We understand insurance",
    ourstrength: "Our Strength That Make Us The Best Partner",
    experienced: "Experienced",
    textexperienced:
      "With extensive experience in the insurance industry since 1999,  we’re strongly confident with our comprehensive solutions",
    benefitpersonal: "Benefit Personalisation",
    textbenefitpersonal: "We design benefit that fits the needs",
    accuraterecommendation: "Accurate Recommendation",
    textaccuraterecommendation:
      "Get the best insurance reccomendation from professional staff",
    titlesolvability: "Solvability",
    titlestability: "Stability",
    titlereputation: "Reputation",
    titleprecision: "Precise",
    titlemanagement: "Management",
    titlespeed: "Speed",
  },
  ourmanagement: {
    title: "Our Management",
    subtitle: "The best people in the business that built our foundation",
    director: "Director",
    detaildirector:
      "With over 35 years of experience in the insurance industry,  Lanny Hanani has held various positions ranging from Actuary to Director at various insurance companies. With a master degree in Mathematics from ITB and expertise in designing customized insurance products that meet client needs,  she founded Andika Agency on September 1st,  1999,  with the goal of providing comprehensive insurance solutions.",
    commisioner: "Commisioner",
    financedir: "Finance Director",
    detailcommisioner1:
      "With 15 years of experience in underwriting and marketing at various reputable insurance companies,  including both joint ventures and national insurers,  Muhammad Arifinhas a wealth of knowledge and expertise in selecting top-quality insurance products.",
    detailcommisioner2:
      "Our Finance Director has a background in accounting and over 28 years of experience in finance,  accounting,  and taxation at various insurance brokerage firms. With a Bachelor's degree in Accounting,  Anton Trilaksana is highly qualified and trusted to oversee the financial operations at Andika Assistant.",
  },
  career: {
    title: "Career In Andika Assistant",
    subtitle: "Andika Assistant consistently creates a personal experience",
    easyapply: "Easy Apply",
    undeeasyapply:
      "Choose an option to autocomplete your application. You can still fill your profile manually.",
    top: "Join Our Team",
    contenttop:
      "Andika Assistant is a Corporate Health Insurance Consultant. With us, you will have the opportunity to work with a variety of clients across different industries.",
    ourbenefit: "Our Benefit",
    momentoftruth: "Moment of Truth",
    detaillist: "Find a job that suits your passion",
    contentdetaillist:
      "We open opportunities for the best candidates to join in our Company.",
  },
  contactus: {
    title: "Contact Us",
    subtitle: "We’ll reply your message right away",
    address: "Address",
    phone: "Phone",
    email: "E-mail",
    operation: "Operational Time",
    timeoperation: "Monday-Friday 08.30 - 17.30",
    info: "We guarantee your data confidentiality. By clicking Submit,  you agree to our Terms & Conditions and Privacy Policy.",
  },
  article: {
    title: "Article",
    subtitle: "Andika Stories,  Updates and Articles for you",
  },
  label: {
    firstname: "Firstname",
    lastname: "Lastname",
    email: "E-mail",
    phone: "Phone Number",
    message: "Consult your insurance needs here...",
    address: "Address",
    experience_title: "Title",
    ex_company: "Company",
    address_ex_company: "Company address",
    description_experience: "Description",
    experience_from: "From",
    experience_to: "To",
    education_institution: "Institution",
    major: "Major",
    degre: "Degree",
    school_location: "Address",
    school_from: "From",
    school_to: "To",
    curentlywork: "I currently work here",
    selecfile: "Select File",
  },
  placeholder: {
    firstname: "Firstname *",
    lastname: "Lastname *",
    email: "E-mail *",
    phone: "Phone Number",
    message: "Consult your insurance needs here...*",
    address: "Address",
    experience_title: "Title",
    ex_company: "Company",
    address_ex_company: "Company address",
    description_experience: "Description",
    experience_from: "From",
    experience_to: "To",
    education_institution: "Institution",
    major: "Major",
    degre: "Degree",
    school_location: "Address",
    school_from: "From",
    school_to: "To",
    carilowongan: "Search",
  },
  validation: {
    empty: "Please fill out this field!",
    sentemail: "Thank you,  our team will contact you soon",
    email: "The input is not valid E-mail!",
  },
  button: {
    seedetail: "See Detail",
    next: "Next",
    prev: "Previous",
    submit: "Submit",
    cari: "Search",
    seemore: "See More",
  },
  footer: {
    aboutus: "About Us",
    profile: "Profile",
    whyandika: "Why Andika",
    contactus: "Contact Us",
    article: "Article",
    healthinsurance: "Health Insurance",
    accidentinsurance: "Accident Insurance",
    lifeinsurance: "Life Insurance",
    connectus: "Connect with Us",
    download: "Download the App",
  },
};

export default english;
