const IndexAdminFileManager = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/Administrator/file-manager/index.vue"
  );

const ListFileManager = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/Administrator/file-manager/list.vue"
  );

// const CreateFileManager = () =>
//   /* webpackChunkName: "view-[request]" */ import(
//     "@/views/Administrator/file-manager/create.vue"
//   );

// const EditFileManager = () =>
//   /* webpackChunkName: "view-[request]" */ import(
//     "@/views/Administrator/file-manager/edit.vue"
//   );

export default [
  {
    path: "file-manager",
    name: "IndexAdminFileManager",
    component: IndexAdminFileManager,
    meta: { requiresAuth: true, title: "File Manager" },
    children: [
      {
        path: "",
        name: "ListFileManager",
        component: ListFileManager,
        meta: {
          requiresAuth: true,
          title: "List File Manager",
          key: "File Manager",
        },
      },
      //   {
      //     path: "create",
      //     name: "CreateFileManager",
      //     component: CreateFileManager,
      //     meta: {
      //       requiresAuth: true,
      //       title: "Create FileManager",
      //       key: "Create FileManager",
      //     },
      //   },
      //   {
      //     path: "edit/:id",
      //     name: "EditFileManager",
      //     component: EditFileManager,
      //     meta: {
      //       requiresAuth: true,
      //       title: "Edit FileManager",
      //       key: "Edit FileManager",
      //     },
      //   },
    ],
  },
];
