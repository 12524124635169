import $axios from "@/utils/axios.js";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  GetListCareer(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get("/administrator/career", {
          params: { ...params },
        })
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },

  InsertCareer(context, values) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .post("/administrator/career/create", {
          ...values,
        })
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },

  FetchEditCareer(context, payload) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get("/administrator/career/edit/" + payload.id)
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },

  DeleteCareer(context, payload) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .delete("/administrator/career/delete/" + payload.id)
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },

  EditCareer(context, payload) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .put("/administrator/career/edit/" + payload.id, {
          ...payload,
        })
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },

  FetchIcon(context, payload) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get("/administrator/career/icon")
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
};

export default {
  namespace: true,
  state,
  getters,
  mutations,
  actions,
};
