import $axios from "@/utils/axios.js";
import moment from "moment";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  ContactStore(context, payloads) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .post("/public/contact", {
          ...payloads,
        })
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
  GetPublicPost(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get("/public/post", {
          params: {
            ...params,
          },
        })
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },

  GetDetailPublicPost(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get("/public/post/detail/" + params.id)
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },

  GetCareerList(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get("/public/career", {
          params: {
            ...params,
          },
        })
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
  GetDetailCareer(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get("/public/career/apply/" + params.id)
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
  SendDataPelamar(context, payload) {
    this.state.isLoading = true;
    const formData = new FormData();
    formData.append("cv", payload.File == undefined ? "" : payload.File);
    formData.append(
      "firstname",
      payload.firstname == undefined ? "" : payload.firstname
    );
    formData.append(
      "lastname",
      payload.lastname == undefined ? "" : payload.lastname
    );
    formData.append("email", payload.email == undefined ? "" : payload.email);
    formData.append(
      "address",
      payload.address == undefined ? "" : payload.address
    );
    formData.append("phone", payload.phone == undefined ? "" : payload.phone);
    formData.append(
      "experience_title",
      payload.experience_title == undefined ? "" : payload.experience_title
    );
    formData.append(
      "ex_company",
      payload.ex_company == undefined ? "" : payload.ex_company
    );
    formData.append(
      "address_ex_company",
      payload.address_ex_company == undefined ? "" : payload.address_ex_company
    );
    formData.append(
      "description_experience",
      payload.description_experience == undefined
        ? ""
        : payload.description_experience
    );
    formData.append(
      "experience_from",
      payload.experience_from == undefined
        ? ""
        : moment(payload.experience_from).format("YYYY-MM-DD")
    );
    formData.append(
      "experience_to",
      payload.experience_to == undefined
        ? ""
        : moment(payload.experience_to).format("YYYY-MM-DD")
    );

    formData.append(
      "current_work",
      payload.agreement == undefined ? "" : payload.agreement
    );

    formData.append(
      "education_institution",
      payload.education_institution == undefined
        ? ""
        : payload.education_institution
    );
    formData.append("major", payload.major == undefined ? "" : payload.major);
    formData.append("degre", payload.degre == undefined ? "" : payload.degre);
    formData.append(
      "school_location",
      payload.school_location == undefined ? "" : payload.school_location
    );
    formData.append(
      "school_from",
      payload.school_from == undefined
        ? ""
        : moment(payload.school_from).format("YYYY-MM-DD")
    );
    formData.append(
      "school_to",
      payload.school_to == undefined
        ? ""
        : moment(payload.school_to).format("YYYY-MM-DD")
    );

    return new Promise((resolve, reject) => {
      $axios
        .post("/public/career/apply/" + payload.id, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
};

export default {
  namespace: true,
  state,
  getters,
  mutations,
  actions,
};
