<template>
  <div id="app">
    <nprogress-container />
    <router-view />
  </div>
</template>

<script>
import NprogressContainer from "vue-nprogress/src/NprogressContainer";
export default {
  metaInfo: {
    meta: [
    
      { vmid: 'description', name: 'description', content: 'Andika Broker merupakan salah satu broker asuransi terbaik di Indonesia. Sebagai broker asuransi, Kami siap membantu Anda dalam memilih asuransi sesuai dengan kebutuhan Anda.' },
      { name: 'keywords', content: 'broker,asuransi,jakarta,indonesia' },
      
     
    ],
    
    
    
    
  },
 
  components: {
    NprogressContainer,
  },
  data() {
    return {
      pageloading: true,
    };
  },
  created() {
    setTimeout(() => {
      this.pageloading = false;
    }, 3000);
  },
};
</script>

<style>
@font-face {
  font-family: "Mina";
  src: url("assets/css/fonts/Mina-Bold.woff2") format("woff2"),
    url("assets/css/fonts/Mina-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mina";
  src: url("assets/css/fonts/Mina-Regular.woff2") format("woff2"),
    url("assets/css/fonts/Mina-Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: Mina !important;
  font-weight: 600;
}

#app {
  font-family: Mina;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
