const indonesia = {
  menu: {
    home: "Home",
    product: "Produk",
    company: "Tentang Kami",
    contact: "Hubungi Kami",
    article: "Artikel",
    aboutus: "Profil",
    whyandika: "Mengapa Memilih Andika",
    ourmanagement: "Manajemen Andika",
    career: "Karir",
  },
  header: {
    slider1: "Layanan Maksimal Lebih dari Sekadar Asuransi",
    tagline1:
      "Tak hanya memberi solusi asuransi,  Andika Assistant berkomitmen memberikan layanan menyeluruh",
    button: "Konsultasi Sekarang",
    slider2: "Rekomendasi Asuransi Sesuai Kebutuhan",
    tagline2: "Saran profesional dan akurat untuk mendapatkan proteksi terbaik",
    slider3: "Pilih Asuransi Tanpa Khawatir",
    tagline3:
      "Andika Assistant hadir untuk memberi rasa aman dan nyaman dalam memilih asuransi",
    slider4: "Melayani dengan Hati",
    tagline4:
      "Pendampingan personal yang menghadirkan ketenangan dan kenyamanan dalam segala kondisi",
  },
  home: {
    history: "Sejarah Andika Assistant",
    historydetail:
      "Berawal dari nama Andika Agency,  perusahaan ini didirikan pada tanggal 1 September 1999 dengan nama PT. Andika Adhi Sejahtera. Dan sejak tanggal 5 November 2012 berkembang menjadi Andika Broker dengan nama PT. Andika Mitra Sejati.",
    solution: "Solusi Masalah Asuransi Anda",
    taglinesolution:
      "Layanan Maksimal Andika Assistant untuk asuransi perusahaan",
    milestonesolution1: "Saran Tepercaya",
    submilestonesolution1:
      "Informasi asuransi secara detail dari staf profesional",
    milestonesolution2: "Layanan Komprehensif",
    submilestonesolution2:
      "Layanan asuransi terbaik,  terlengkap,  dan dengan harga yang sepadan",
    milestonesolution3: "Pendampingan Klaim",
    submilestonesolution3:
      "Dukungan klaim mulai dari pengajuan,  proses hingga disetujui",
    milestonesolution4: "Produk Sesuai Kebutuhan",
    submilestonesolution4:
      "Merekomendasikan produk yang disesuaikan secara khusus",
    personalaccident: "Asuransi Kecelakaan",
    healtinsurance: "Asuransi Kesehatan Kumpulan",
    lifeinsurance: "Asuransi Jiwa Kumpulan",
    provider: "Menemukan Provider Tak Pernah Semudah Ini",
    subprovider:
      "Dengan aplikasi Andika Assistant,  menemukan provider asuransi menjadi lebih mudah. Hanya dalam beberapa sentuhan,  provider bisa ditemukan beserta informasi yang detail dan lengkap.",
    accuration: "Akurasi",
    rating: "Rating",
    benefit: "Detail Informasi Manfaat",
    subbenefit: "Ketahui detail manfaat Anda lewat aplikasi Andika Assistant",
    outpatient: "Rawat Jalan",
    suboutpatient:
      "Manfaat konsultasi dokter umum,  spesialis termasuk obat-obatan untuk penyembuhan penyakit Anda secara optimal",
    inpatient: "Rawat Inap",
    subinpatient:
      "Manfaat biaya kamar,  tindakan bedah,  dan perawatan pasca-tindakan",
    dental: "Kesehatan Gigi",
    subdental:
      "Manfaat kunjungan berkala ke dokter gigi,  pencegahan penyakit gigi,  penambalan dan pencabutan gigi",
    glasses: "Kesehatan Mata",
    subglasses:
      "Manfaat pemeriksaan mata,  penggantian lensa kaca mata atau kontak,  sampai bingkai kaca mata",
    claimfingertips: "Ajukan Klaim Tinggal Tap!",
    subclaimfingertips:
      "Mudah,  cepat,  dan transparan di aplikasi Andika Assistant",
    claimsubmission: "Ajukan Klaim",
    subclaimsubmission:
      "Hanya dengan beberapa sentuhan,  klaim Anda langsung diproses. Tanpa repot!",
    claimmonitoring: "Pantau Klaim",
    subclaimmonitoring:
      "Informasi klaim diberikan secara transparan demi kenyamanan Anda.",
    claimhistory: "Riwayat Klaim",
    subclaimhistory:
      "Semua klaim yang pernah Anda buat tercatat rapi,  tinggal klik!",
    asuransikesehatan: "Asuransi Kesehatan Dalam Genggaman",
    subasuransikesehatan: "Satu aplikasi untuk semua kebutuhan Asuransi Anda",
    ourpartner: "Partner Kami",
    latestarticle: "Artikel Terbaru",
  },
  product: {
    title: "Produk Kami",
    subtitle: "Proteksi maksimal sesuai kebutuhan",
    detailhealth:
      '<p style="text-align: justify;">Asuransi kesehatan merupakan salah satu investasi penting bagi perusahaan. Menyediakan asuransi kesehatan berarti menghadirkan rasa aman dan nyaman bagi karyawan dalam segala kondisi. Tak hanya itu, asuransi kesehatan dengan manfaat yang optimal, akan menjadi daya tarik tersendiri bagi perusahaan Anda. Di Andika Assistant, perusahaan Anda bisa mendapatkan asuransi kesehatan terlengkap, meliputi:</p><ul><li>Rawat Inap &amp; Bedah </li><li>Rawat Jalan </li><li>Persalinan </li><li>Rawat Gigi </li><li>Kacamata </li><li>Manfaat Tambahan Berupa Evakuasi Darurat, Santunan Meninggal Dunia dan Manfaat Lainnya </li></ul><p style="text-align: justify;">Andika Assistant menjamin layanan komprehensif dalam memilih asuransi kesehatan. Mulai dari rekomendasi asuransi dari staf profesional, bantuan pemilihan asuransi yang tepat, sampai dukungan helpline 24 jam. Semua kelebihan itu juga disempurnakan dengan layanan analisa klaim untuk memastikan klaim Anda dibuat dengan dasar yang tepat agar disetujui provider. </p>',
    detailaccident:
      "<p>Karyawan adalah aset yang tidak ternilai bagi perusahaan. Maka dari itu penting untuk melindungi mereka dari berbagai kemungkinan yang dapat terjadi. Bagi perusahaan yang bergerak di industri dengan risiko kecelakaan tinggi, Asuransi Kecelakaan Diri adalah solusi untuk memberi perlindungan, sekaligus menghadirkan rasa aman dan nyaman dalam bekerja bagi karyawan Anda.</p><p>Asuransi Kecelakaan Diri utamanya memberi perlindungan akibat kecelakaan kepada tertanggung. Selain itu, keluarga tertanggung juga akan mendapat uang sejumlah nilai pertanggungan jika terjadi hal yang tidak diinginkan. Dapatkan rekomendasi asuransi kecelakaan yang tepat sesuai kebutuhan Anda, beserta asuransi tambahan, seperti:</p><ol><li>Cacat Tetap</li><li>Cacat Sementara</li><li>Biaya Pengobatan</li></ol><p>Andika Assistant menjamin layanan komprehensif dalam memilih asuransi kecelakaan diri. Mulai dari rekomendasi asuransi dari staf profesional, bantuan pemilihan asuransi yang tepat, sampai dukungan helpline 24 jam. Semua kelebihan itu juga disempurnakan dengan layanan analisa klaim untuk memastikan klaim Anda dibuat dengan dasar yang tepat agar disetujui provider.</p>",
    detaillife:
      '<p style="text-align:justify;">Memiliki asuransi jiwa merupakan salah satu bentuk kasih sayang kepada orang-orang yang Anda cintai. Tujuan dari asuransi jiwa adalah memastikan orang-orang terdekat Anda bisa tetap hidup berkecukupan, bahkan saat Anda tak lagi bersama mereka. </p><p>Karakteristik Produk Asuransi Jiwa:</p><ul><li>Memberikan pertanggungan selama satu jangka waktu tertentu yang disebut policy term (jangka waktu polis). </li><li>Perlindungan polis asuransi jiwa yang baik biasanya memiliki manfaat tambahan (rider) yang ditambahkan pada polis dasar. </li></ul><p>Manfaat Tambahan (rider) Asuransi Jiwa:</p><ul><li><p><strong>Santunan Kematian dan Cacat Akibat Kecelakaan (Accident Death Disablement Benefit)</strong></p><p>Manfaat sebesar 100% uang pertanggungan bila meninggal dunia karena kecelakaan. Untuk kondisi cacat karena kecelakaan, pertanggungan akan disesuaikan dengan tabel persentase. </p></li><li><p><strong>Santunan Cacat Total dan Tetap (Total Permanent Disablement Benefit)</strong></p><p>Manfaat sebesar 100% uang pertanggungan apabila tertanggung mengalami cacat total akibat sakit atau kecelakaan dalam waktu 90 hari sejak terjadinya insiden. </p></li></ul><p style="text-align:justify;">Andika Assistant menjamin layanan komprehensif dalam memilih asuransi jiwa. Mulai dari rekomendasi asuransi dari staf profesional, bantuan pemilihan asuransi yang tepat, sampai dukungan helpline 24 jam. Semua kelebihan itu juga disempurnakan dengan layanan analisa klaim untuk memastikan klaim Anda dibuat dengan dasar yang tepat agar disetujui provider. </p>',
  },
  profile: {
    title: "Profil",
    subtitle: "Konsultan asuransi dengan pelayanan #1 di Indonesia",
    aboutandika: "Tentang Andika Assistant",
    textaboutandika:
      "Kami adalah konsultan asuransi yang memprioritaskan Anda. Kami pastikan layanan menyeluruh dari produk pilihan Anda.",
    helpyou: "Kami Bantu Anda Mengerti Asuransi",
    texthelpyou:
      "Kami ingin Anda juga mengerti detail segala rekomendasi asuransi yang diberikan. Oleh karenanya,  kami berbagi keahlian dan pengetahuan yang membuat Anda yakin telah mengambil keputusan yang tepat.",
    commitment: "Komitmen Kami",
    textcommitment:
      "Kami berkomitmen untuk selalu memprioritaskan Anda dengan rekomendasi tepercaya disertai pengetahuan produk yang menyeluruh.",
    textiserve: "Nilai utama yang kami jaga",
    innovative: "Menciptakan nilai tambah untuk Anda melalui inovasi",
    spiritofexcellent: "Memberikan lebih dari yang terbaik untuk Anda",
    emphaty: "Memahami kebutuhan Anda dan berusaha memberi solusi terbaik",
    reliable: "Bisa diandalkan untuk segala keperluan asuransi Anda",
    versatile:
      "Mampu beradaptasi sesuai kondisi dan situasi dengan tetap menjaga kualitas",
    enthusiastic:
      "Selalu positif,  bersemangat dan proaktif dalam memberikan pelayanan",
  },
  whyandika: {
    title: "Kenapa Harus Memilih Andika?",
    subtitle: "Beragam nilai tambah dalam menyediakan kebutuhan asuransi",
    bestinsurance: "Asuransi Terbaik,  Dimulai dari Sini",
    textbestinsurance:
      "Asuransi tak lagi jadi masalah setelah memilih Andika Assistant. Kami berkomitmen untuk membantu dalam setiap permasalahan asuransi perusahaan. Layanan menyeluruh yang kami berikan tak hanya memastikan asuransi yang tepat,  namun juga dukungan dalam pengajuan serta penyelesaian klaim.",
    criteriainsurance: "Kriteria Asuransi Partner Kami",
    textcriteriainsurance:
      "Kami bekerja sama dengan perusahaan asuransi pilihan untuk memastikan layanan terbaik",
    solvability:
      "Perusahaan asuransi rekanan kami diharuskan memiliki tingkat solvabilitas yang tinggi",
    stability:
      "Kondisi keuangan perusahaan asuransi rekanan kami terjamin stabilitasnya",
    reputation:
      "Perusahaan asuransi rekanan kami,  hanyalah perusahaan dengan reputasi terbaik",
    precise:
      "Layanan yang diberikan harus sesuai dengan komitmen kami dalam memberikan yang terbaik",
    management:
      "Kami pastikan perusahaan asuransi rekanan kami dikelola oleh profesional terbaik di bidangnya",
    speed:
      "Perusahaan asuransi rekanan kami pastikan memberi pelayanan terbaik dalam waktu sesingkat-singkatnya",
    comprehensive: "Memberikan Layanan Komprehensif",
    textcomprehensive:
      "Membantu permasalahan asuransi secara utuh dan optimal.",
    benefit: "Analisa Kecukupan Manfaat",
    textbenefit:
      "Rekomendasi dan manfaat yang dirancang secara khusus sesuai kebutuhan",
    insuranceselection: "Seleksi Asuransi",
    textinsuranceselection:
      "Kami pilihkan asuransi sesuai kebutuhan dan memberikan proteksi terbaik",
    helpline: "Bantuan 24/7",
    texthelpline: "Tak kenal waktu,  Helpline kami siap membantu setiap saat",
    negotiation: "Negosiasi Klaim",
    textnegotiation:
      "Membantu negosiasi klaim demi mendapatkan hak yang sesuai",
    accompaniment: "Pendampingan",
    textaccompaniment:
      "Kami dampingi perjalanan Anda dalam menentukan asuransi dari awal hingga tuntas",
    administration: "Administrasi Data",
    textadministration:
      "Penyimpanan data peserta yang aman,  rapi,  dan akurat",
    undestandinsurance: "Kami memahami asuransi yang Anda butuhkan",
    ourstrength: "Kelebihan yang Menjadikan Kami Partner Terbaik untuk Anda",
    experienced: "Berpengalaman",
    textexperienced:
      "Telah memberi solusi asuransi sejak 1999 membuat kami memiliki pengalaman terlengkap di bidang ini",
    benefitpersonal: "Personalisasi Manfaat",
    textbenefitpersonal:
      "Kami menyesuaikan manfaat (benefit) sesuai dengan kebutuhan",
    accuraterecommendation: "Rekomendasi Tepercaya",
    textaccuraterecommendation: "Rekomendasi akurat dari tim yang profesional",
    titlesolvability: "Solvabilitas",
    titlestability: "Stabilitas",
    titlereputation: "Reputasi",
    titleprecision: "Presisi",
    titlemanagement: "Manajemen",
    titlespeed: "Kecepatan",
  },
  ourmanagement: {
    title: "Manajemen Kami",
    subtitle:
      "Dewan Direksi Berpengalaman yang Menjadi Fondasi Andika Assistant",
    director: "Direktur",
    detaildirector:
      "Lebih dari 35 tahun berkarier di dunia asuransi,  mulai dari menjabat sebagai Aktuaris hingga menjadi Direktur di beberapa perusahaan asuransi. Lulusan Doktoranda Matematika ITB ini sangat menguasai produk asuransi dan piawai dalam mendesain produk yang cocok dan sesuai dengan kebutuhan klien,  sehingga memutuskan untuk mendirikan Andika Agency yang dirintis sejak tanggal 1 September 1999 dengan nama PT. Andika Adhi Sejahtera.",
    commisioner: "Komisaris",
    financedir: "Direktur Keuangan",
    detailcommisioner1:
      "15 tahun berkarier di dunia asuransi sebagai Underwriting dan Marketing di beberapa Perusahaan asuransi ternama,  baik asuransi Joint Venture maupun Asuransi Nasional. Dengan pengalaman yang dimiliki,  keputusannya dalam memilih produk asuransi tak perlu diragukan lagi.",
    detailcommisioner2:
      "Memiliki pengalaman di bidang Keuangan,  Akuntansi dan Perpajakan. Dengan latar belakang lulusan Sarjana Akuntansi serta sudah lebih dari 28 tahun berkarier di bidang Keuangan,  Akuntansi dan Perpajakan dari beberapa Perusahaan Broker Asuransi,  beliau dipercaya menjadi Finance Director Andika Assistant.",
  },

  career: {
    title: "Andika Assistant Karir",
    subtitle: "Andika Assistant konsisten menciptakan pengalaman personal",

    easyapply: "Proses Pendaftaran Mudah",
    undeeasyapply:
      "Pilih opsi untuk melengkapi aplikasi Anda secara otomatis. Anda tetap dapat mengisi profil secara manual.",
    top: "Bergabung Menjadi Team Kami",
    contenttop:
      "Andika Assistant adalah perusahaan yang bergerak di bidang Coporate Health Insurance Consultant. Bersama kami, anda akan mendapatkan kesempatan untuk bekerjasama dengan berbagai client dari beragam industri.",
    ourbenefit: "Benefit Kami",
    momentoftruth: "Momen Penting",
    detaillist: "Temukan pekerjaan yang sesuai dengan minat anda",
    contentdetaillist:
      "Kami membuka peluang bagi kandidat terbaik untuk bergabung di Perusahaan kami.",
  },

  contactus: {
    title: "Hubungi Kami",
    subtitle: "Kirimkan pertanyaan Anda dan kami akan balas segera",
    address: "Alamat",
    phone: "Telp",
    email: "E-mail",
    operation: "Waktu Operasi",
    timeoperation: "Senin - Jumat 08.30 - 17.30",
    info: "Informasi yang Anda berikan dijamin kerahasiaannya. Dengan mengirim pesan kepada kami,  Anda dianggap menyetujui syarat dan ketentuan.",
  },
  article: {
    title: "Artikel Terbaru",
    subtitle: "Info terkini Andika Assistant dan artikel seputar kesehatan",
  },

  label: {
    firstname: "Nama Depan",
    lastname: "Nama Belakang",
    email: "E-mail",
    phone: "Nomor Telepon",
    message: "Konsultasikan permasalahan asuransi Anda di sini...",
    address: "Alamat",
    experience_title: "Posisi/Jabatan",
    ex_company: "Perusahaan",
    address_ex_company: "Alamat Perusahaan",
    description_experience: "Deskripsi",
    experience_from: "Mulai Bekerja",
    experience_to: "Sampai",
    education_institution: "Institusi",
    major: "Jurusan",
    degre: "Gelar",
    school_location: "Alamat",
    school_from: "Dari",
    school_to: "Sampai",
    curentlywork: "Masih Bekerja disini",
    selecfile: "Pilih Dokumen",
  },

  placeholder: {
    firstname: "Nama Depan *",
    lastname: "Nama Belakang *",
    email: "E-mail *",
    phone: "Nomor Telepon *",
    message: "Konsultasikan permasalahan asuransi Anda di sini... *",
    address: "Alamat",
    experience_title: "Posisi/Jabatan",
    ex_company: "Perusahaan",
    address_ex_company: "Alamat Perusahaan",
    description_experience: "Deksripsi",
    experience_from: "Mulai Bekerja",
    experience_to: "Sampai",
    education_institution: "Institusi",
    major: "Jurusan",
    degre: "Gelar",
    school_location: "Alamat",
    school_from: "Dari",
    school_to: "Sampai",
    carilowongan: "Cari Lowongan",
  },

  validation: {
    empty: "Silahkan isi field ini!",
    sentemail: "Terimakasih,  team kami akan segera menghubungi anda",
    email: "Silahkan isi dengan email yang valid",
  },
  button: {
    seedetail: "Selengkapnya",
    next: "Selanjutnya",
    prev: "Sebelumnya",
    submit: "Submit",
    cari: "Cari",
    seemore: "Lihat Semua",
  },

  footer: {
    aboutus: "Tentang Kami",
    profile: "Profil",
    whyandika: "Mengapa Memilih Andika",
    contactus: "Hubungi Kami",
    article: "Artikel",
    healthinsurance: "Asuransi Kesehatan",
    accidentinsurance: "Asuransi Kecelakaan",
    lifeinsurance: "Asuransi Jiwa",
    connectus: "Sosial Media Kami",
    download: "Temukan Kami",
  },
};

export default indonesia;
