import $axios from "@/utils/axios.js";

const state = {
  uploadPercentage: 0,
};

const getters = {};

const mutations = {};

const actions = {
  DeleteFileManager(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .delete("/administrator/filemanager/delete/" + params.id)
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
  getFileManager(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get("/administrator/filemanager", {
          params: {
            ...params,
          },
        })
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
  UploadFileManager(context, params) {
    let ini = this;
    this.state.isLoading = true;
    const formData = new FormData();
    formData.append("files", params.file == undefined ? "" : params.file);
    return new Promise((resolve, reject) => {
      $axios
        .post("/administrator/filemanager/create", formData, {
          onUploadProgress: function (progressEvent) {
            const uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
            ini.state.FileManager.uploadPercentage = uploadPercentage;
          },
        })
        .then((response) => {
          this.state.FileManager.uploadPercentage = 0;
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
};

export default {
  namespace: true,
  state,
  getters,
  mutations,
  actions,
};
