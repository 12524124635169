import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index.js";
import Home from "./home";
import Administrator from "./administrator";

Vue.use(VueRouter);

const routes = [].concat(Home).concat(Administrator);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    document.getElementById("app").scrollIntoView({ behavior: "smooth" });
  },
});

router.beforeEach((to, from, next) => {
  let auth = store.getters.isAuth;

  // Find the nearest route element with meta tags.

  if (document.querySelector("meta[name='description']") != undefined) {
    document.querySelector("meta[name='description']").remove();
    document.querySelector("meta[name='keywords']").remove();
    document.querySelector("meta[property='og:description']").remove();
    document.querySelector("meta[property='og:title']").remove();
    document.querySelector("meta[property='og:type']").remove();
    document.querySelector("meta[property='og:url']").remove();
    document.querySelector("meta[property='og:site_name']").remove();
  }

  if (document.querySelector("meta[property='og:image']") != undefined) {
    document.querySelector("meta[property='og:image']").remove();
  }

  if (
    to.matched
      .slice()
      .reverse()
      .find((r) => r.meta && r.meta.metaTags != undefined)
  ) {
    const nearestWithMeta = to.matched
      .slice()
      .reverse()
      .find((r) => r.meta && r.meta.metaTags);
    //  console.log(nearestWithMeta.meta.metaTags);
    nearestWithMeta.meta.metaTags
      .map((tagDef) => {
        const tag = document.createElement("meta");

        Object.keys(tagDef).forEach((key) => {
          tag.setAttribute(key, tagDef[key]);
        });

        // We use this to track which meta tags we create so we don't interfere with other ones.

        return tag;
      })
      // Add the meta tags to the document head.
      .forEach((tag) => document.head.appendChild(tag));
  }

  let titles =
    to.meta.title == "Beranda"
      ? "Employee Benefit & Insurance Consultant"
      : to.meta.title;
  window.document.title =
    to.meta && to.meta.title
      ? "Andika Assistant | " + titles
      : "Andika Assistant";

  if (to.matched.some((record) => record.meta.requiresAuth) && auth) {
    next();
  } else {
    if (to.matched.some((record) => record.meta.requiresAuth) && !auth) {
      next({
        path: "/asa",
        replace: true,
      });
    } else {
      next();
      //console.log('donah')
    }
  }
});

export default router;
