import Welcome from "./welcome";
import Categories from "./categories";
import Post from "./post";
import FileManager from "./filemanager";
import Career from "./career";
import Applicant from "./applicant";

const router = []
  .concat(Welcome)
  .concat(Categories)
  .concat(Post)
  .concat(FileManager)
  .concat(Career)
  .concat(Applicant);

export default router;
