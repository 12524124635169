const IndexAdminCareer = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/Administrator/career/index.vue"
  );

const ListCareer = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/Administrator/career/list.vue"
  );

const CreateCareer = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/Administrator/career/create.vue"
  );

const EditCareer = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/Administrator/career/edit.vue"
  );

export default [
  {
    path: "career",
    name: "IndexAdminCareer",
    component: IndexAdminCareer,
    meta: { requiresAuth: true, title: "Career" },
    children: [
      {
        path: "",
        name: "ListCareer",
        component: ListCareer,
        meta: {
          requiresAuth: true,
          title: "List Career",
          key: "Career",
        },
      },
      {
        path: "create",
        name: "CreateCareer",
        component: CreateCareer,
        meta: {
          requiresAuth: true,
          title: "Create Career",
          key: "Create Career",
        },
      },
      {
        path: "edit/:id",
        name: "EditCareer",
        component: EditCareer,
        meta: {
          requiresAuth: true,
          title: "Edit Career",
          key: "Edit Career",
        },
      },
    ],
  },
];
